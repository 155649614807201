<template>
  <div class="meetingInfo_container global_full_screen">
    <itemScroll ref="itemScroll">
      <div class="meetingInfo_main">
        <div class="meetingInfo_body">
          <div class="meetingInfo_header ">
            <img :src="require('@/assets/images/icon/meeting_info.png')" alt="">
            <span>会议信息</span>
          </div>
          
          <!-- <div class="meetingInfo_row " v-if="config.projects.length > 1">
              <div class="meetingInfo_key">会议项目:</div>
              <div class="meetingInfo_value global_text_input">
                  <el-select v-model="data.project_id" placeholder="请选择项目" @change="changeProject" >
                    <el-option v-for="(item,index) in config.projects" :key="index" :label="item.project" :value="item.id"></el-option>
                  </el-select>
                  <div class="global_container_mask" v-if="isDisabled()"></div>
              </div>
          </div> -->
          <div class="meetingInfo_row ">
              <div class="meetingInfo_key">会议名称:</div>
              <div class="meetingInfo_value global_text_input">
                  <el-input v-model="data.name" placeholder="请输入会议名称"></el-input>
                  <div class="global_container_mask" v-if="isDisabled()"></div>
              </div>
          </div>
          <div class="meetingInfo_row ">
              <div class="meetingInfo_key">会议日期:</div>
              <div class="meetingInfo_value global_text_input">
                <el-date-picker v-model="meetingTime.date" :editable="false" :value-format="`YYYY-MM-DD`" type="date" placeholder="请选择会议日期"></el-date-picker>
                <div class="global_container_mask" v-if="isDisabled()"></div>
              </div>
          </div>
          <div class="meetingInfo_row ">
              <div class="meetingInfo_key">开始时间:</div>
              <div class="meetingInfo_value global_text_input">
                  <el-select v-model="meetingTime.startTime" @change="changeStartTime" placeholder="请选择开始时间" >
                    <el-option v-for="(item,index) in config.dateList.slice(0,-2)" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <div class="global_container_mask" v-if="isDisabled()"></div>
              </div>
          </div>
          <div class="meetingInfo_row ">
              <div class="meetingInfo_key">结束时间:</div>
              <div class="meetingInfo_value global_text_input">
                  <el-select v-model="meetingTime.endTime" placeholder="请选择结束时间">
                    <el-option v-for="(item,index) in config.dateList" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <div class="global_container_mask" v-if="isDisabled()"></div>
              </div>
          </div>
          <div class="meetingInfo_row ">
              <div class="meetingInfo_key">会议讲题:</div>
              <div class="meetingInfo_value meetingInfo_value_courseware_block">
                  <!-- <el-select v-model="data.courseware_id" placeholder="请选择会议讲题" >
                    <el-option v-for="(item,index) in config.coursewares" :key="index" :label="item.title" :value="item.id"></el-option>
                  </el-select> -->
                  <div class="meetingInfo_courseware" @click="wantSelectCourseware" :class="{meetingInfo_courseware_select:data.courseware_id}">{{getCoursewareTitle() || "请选择会议讲题"}}</div>
                  <customButton  v-if="data.courseware_id" @click="goToPreview">课件预览</customButton>
                  <div class="global_container_mask" v-if="isDisabled()"></div>
              </div>
          </div>
        </div>
        <div class="meetingInfo_body">
          <div class="meetingInfo_header">
            <img :src="require('@/assets/images/icon/meeting_info.png')" alt="">
            <span>主持专家信息</span>
          </div>
          <p class="doctor_resumeInfo_tips" v-if="isHaveDoctorPage()">注：如需修改请至上页专家个人信息</p>
          <div class="meetingInfo_row ">
              <div class="doctor_resumeInfo_key">姓名:</div>
              <div class="meetingInfo_value global_text_input">
                  <span>{{doctorInfo.name}}</span>
              </div>
          </div>
          <div class="meetingInfo_row ">
              <div class="doctor_resumeInfo_key">省份:</div>
              <div class="meetingInfo_value global_text_input">
                  <span>{{doctorInfo.province}}</span>
              </div>
          </div>
          <div class="meetingInfo_row ">
              <div class="doctor_resumeInfo_key">城市:</div>
              <div class="meetingInfo_value global_text_input">
                  <span>{{doctorInfo.city}}</span>
              </div>
          </div>
          <div class="meetingInfo_row ">
              <div class="doctor_resumeInfo_key">医院:</div>
              <div class="meetingInfo_value global_text_input">
                  <span>{{doctorInfo.hospital}}</span>
              </div>
          </div>
          <div class="meetingInfo_row " v-if="isHaveDoctorPage()">
              <div class="doctor_resumeInfo_key">专家简历职称:</div>
              <div class="meetingInfo_value resume_text_input">
                  <span>{{doctorInfo.resume||"暂无"}}</span>
              </div>
          </div>
          
          <div class="meetingInfo_row " v-if="isHaveDoctorPage()">
              <div class="doctor_resumeInfo_key">简历行政职务:</div>
              <div class="meetingInfo_value resume_text_input">
                  <span>{{doctorInfo.job||"暂无"}}</span>
              </div>
          </div>
          
          <div class="meetingInfo_row " v-if="isHaveDoctorPage()">
              <div class="doctor_resumeInfo_key">简历学术任职<br>及其他:</div>
              <div class="meetingInfo_value resume_text_input">
                  <span>{{doctorInfo.resume_academic ? doctorInfo.resume_academic.replace(/[,\n\s]/g,"\n") : "暂无"}}</span>
              </div>
          </div>
          
          <div class="meetingInfo_row " v-if="isHaveDoctorPage()">
              <div class="doctor_resumeInfo_key">专家简历照片:</div>
              <div>
                  <div v-if="doctorInfo.profile" class="meetingInfo_value_doctorInfo_profile">
                    <img :src="doctorInfo.profile"/>
                  </div>
                  <span v-else>暂无</span>
              </div>
          </div>
        </div>
      </div>
      <div class="meetingInfo_row meetingInfo_button_group" v-if="!isDisabled()">
          <customButton class="meetingInfo_button" size="small" @click="doCommit">
              <div class="meetingInfo_button_info">
                  <img :src="require('@/assets/images/icon/apply.png')" alt="" />
                  <span>{{this.$route.query.meetId ? "确认修改" : "确认申请"}}</span>
              </div>
          </customButton>
      </div>
    </itemScroll>
    <opacityBg v-if="showSelectCourseware" @click="this.showSelectCourseware=false">  
      <div class="meetingInfo_course_select_container_center" v-if="config.coursewares.length > 0 && config.coursewares.length <= 4">
        <div class="meetingInfo_course_select "  @click.stop="" >
          <div class="meetingInfo_course_select_title global_primary_text_color">会议讲题</div>
          <div class="meetingInfo_course_select_ul" v-for="(item,index) in  config.coursewares" :key="index">
            <el-radio class="meetingInfo_course_select_radio" @change="changeCourseware" v-model="data.courseware_id" :label="item.id" >&nbsp;</el-radio>
            <div class="meetingInfo_course_select_radio_label" @click="doSelectCourseware(item.id)">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="meetingInfo_course_select_container" v-if="config.coursewares.length > 4">
        <itemScroll ref="selectScroll" >
          <div class="meetingInfo_course_select "  @click.stop="" >
            <div class="meetingInfo_course_select_title global_primary_text_color">会议讲题</div>
            <div class="meetingInfo_course_select_ul" v-for="(item,index) in  config.coursewares" :key="index">
              <el-radio class="meetingInfo_course_select_radio" @change="changeCourseware" v-model="data.courseware_id" :label="item.id" >&nbsp;</el-radio>
              <div class="meetingInfo_course_select_radio_label" @click="doSelectCourseware(item.id)">{{item.title}}</div>
            </div>
          </div>
        </itemScroll>
      </div>
    </opacityBg>
  </div>
</template>
<script>
import {ElSelect,ElDatePicker,ElOption,ElRadio,ElInput} from 'element-plus';
import customButton from '@/components/unit/customButton'
import itemScroll from '@/components/unit/itemScroll'
import opacityBg from '@/components/unit/opacityBg'
export default {
  name:"meetingInfo",
  components:{
    itemScroll:itemScroll,
    opacityBg:opacityBg,
    customButton:customButton,
    ElDatePicker,
    ElSelect,
    ElOption,
    ElRadio,
    ElInput
  },
  data(){
    return {
      config:{
        dateList:[],
        coursewares:[],
        projects:[]
      },
      data: {
        doctor_id:"",
        project_id:"",
        name:"",
        start_time:"",
        end_time:"",
        courseware_id:""        
      },
      meetingTime:{
        date:"",
        startTime:"",
        endTime:"",
      },
      projectInfo:{},
      doctorInfo:{},
      loading:false,
      showSelectCourseware:false,
    }
  },
  created(){ 
    this.data.doctor_id = this.$route.query.id;
    this.initDateList()
    this.loadDoctorInfo()
    if(this.$route.query.meetId){
      document.getElementsByTagName("title")[0].innerHTML = "修改会议"
      this.loadData()
    }else{
      document.getElementsByTagName("title")[0].innerHTML = "新建会议"
      let coursewareItem = this.$tools.getStorage("coursewareItem",sessionStorage)
      if(coursewareItem){
        this.data = {
          ...this.data,
          ...coursewareItem.data
        }
        this.meetingTime = {
          ...this.meetingTime,
          ...coursewareItem.meetingTime
        }
      }
      this.$tools.setStorage("coursewareItem","",sessionStorage)
      this.loadProjectInfo()
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.$refs.itemScroll.initScroll()
    })
    // this.loadProjectInfo()
  },
  methods:{
    initDateList(){
      let hour = 6,minute = 30,dateList = [];
      for(;hour < 24; hour++){
        do{
          let time = `${this.$tools.getTimeText(hour)}:${this.$tools.getTimeText(minute)}`;
          dateList.push({
            label:time,
            value:`${time}:00`,
            count: hour * 60 + minute
          })
          minute += 15;
        }while(minute < 60)
        minute = 0;
      }
      this.config.dateList = dateList;
    },
    initDate(){
      let [date,startTime] = this.data.start_time.split(" ");
      let endTime = this.data.end_time.split(" ")[1];
      this.meetingTime = {
        date,
        startTime:startTime.substr(0,5),
        endTime:endTime.substr(0,5)
      }
      console.log(this.meetingTime)
    },
    loadData(){
      let url = this.$tools.getURL(this.$urls.admin.meetDetail,{id:this.$route.query.meetId})
      this.$axios.get(url).then(res=>{
        this.data = res.data;
        console.log(this.data)
        this.initDate()
        this.loadProjectInfo(true)
      }).catch(err=>{})
    },
    loadProject(status=false){
        let url = this.$tools.getURL(this.$urls.api.project)
        this.$axios.get(url).then(res=>{
          this.config.projects = [...res.data].reverse();
          // if(!status) this.data.project_id = res.data[0].id;
          this.$nextTick(()=>{
            this.$refs.itemScroll.initScroll()
          })
          if(this.data.project_id) this.loadCourseware()
        }).catch(err=>{
          console.log(err)
        })
    },
    loadCourseware(){
      let meet_id = this.$route.query.meetId || 0
      let url = this.$tools.getURL(this.$urls.api.courseware,{id:this.data.project_id,doctor_id:this.data.doctor_id,meet_id:meet_id})
      this.$axios.get(url).then(res=>{
          this.config.coursewares = res.data
          if(this.config.coursewares.length > 4){
            this.$refs.selectScroll && this.$refs.selectScroll.initScroll()
          }
          // this.config.coursewares = [
          //   {
          //     title:"Pembrolizumab与化疗作为晚期食管癌二线治疗对KEYNOTE-181健康相关生活质量的影响。",
          //     id:1
            
          //   },
          //   {
          //     title:"Toripalimab加化疗作为先前EGFR-TKI治疗EGFR突变晚期NSCLC患者的二线治疗：多中心II期试验。",
          //     id:2
            
          //   },
          //   {
          //     title:"CCTG BR34:durvalumab和tremelimumab/-铂类化疗治疗转移性非小细胞肺癌的随机II期临床试验。",
          //     id:3
          
          //   }
          // ]
          this.$nextTick(()=>{
            this.$refs.itemScroll.initScroll()
          })
      }).catch(err=>{})
    },
    loadProjectInfo(){
      let url = this.$tools.getURL(
        this.$urls.admin.project,
        {
          project_id:this.$tools.getStorage("projectId",sessionStorage)
        }
      )
      this.$axios.get(url).then(res=>{
        this.projectInfo = res.data
        this.data.project_id = this.projectInfo.id,
        this.loadCourseware()
      }).catch(err=>{})
    },
    loadDoctorInfo(){
        let url = this.$tools.getURL(this.$urls.admin.doctorInfo,this.$route.query)
        this.$axios.get(url).then(res=>{
            this.doctorInfo = res.data;
        })
    },
    isHaveDoctorPage(){
      return !!this.projectInfo.doctor_page
    },
    changeCourseware(){
      this.$nextTick(()=>{
        this.$refs.itemScroll.initScroll()
      })
      this.showSelectCourseware =  false;
    },
    wantSelectCourseware(){
      if(this.config.coursewares.length == 0){
        this.$tips.error({text:"暂无会议讲题"})
        return
      }
      this.showSelectCourseware =  true;
      this.$refs.selectScroll && this.$refs.selectScroll.initScroll()
    },
    doSelectCourseware(id){
      this.data.courseware_id = id;
      this.showSelectCourseware =  false;
      this.$nextTick(()=>{
        this.$refs.itemScroll.initScroll()
      })
    },
    getCoursewareTitle(){
      if(!this.data.courseware_id) return "";
      let item = this.config.coursewares.find(x=>x.id==this.data.courseware_id);
      return item ? item.title : ""
    },
    getEndTime(){
      let index = this.config.dateList.map(val=>val.value).indexOf(this.meetingTime.startTime)
      this.meetingTime.endTime = this.config.dateList[index+2].value;

    },
    changeStartTime(){
      this.getEndTime()
    },
    changeProject(){
      this.data.courseware_id = "";
      this.loadCourseware();
    },
    isDisabled(){
      return !this.$store.state.common.adminInfo.phone
    },
    verifyData(){
      if(!this.data.name){
        this.$tips.error({text:"请输入会议名称"})
        return false;
      }
      if(!this.meetingTime.date){
        this.$tips.error({text:"请选择会议日期"})
        return false;
      }
      if(!this.meetingTime.date){
        this.$tips.error({text:"请选择会议日期"})
        return false;
      }
      if(this.$tools.getTime(this.meetingTime.date) < this.$tools.getTime(this.$tools.getDate())){
        this.$tips.error({text:"会议日期必须在今天之后"})
        return false;
      }
      if(!this.meetingTime.startTime){
        this.$tips.error({text:"请选择开始时间"})
        return false;
      }
      if(!this.meetingTime.endTime){
        this.$tips.error({text:"请选择结束时间"})
        return false;
      }
      this.data.start_time = `${this.meetingTime.date} ${this.meetingTime.startTime}`
      this.data.end_time = `${this.meetingTime.date} ${this.meetingTime.endTime}`
      if(this.$tools.getTime(this.data.start_time) < Date.now() + 30 * 60 * 1000){
        this.$tips.error({text:"会议开始时间必须在半小时以后"})
        return false;
      }
      if(this.$tools.getTime(this.data.start_time) > this.$tools.getTime(this.data.end_time)){
        this.$tips.error({text:"会议开始时间不能大于会议结束时间"})
        return false;
      }
      if(!this.data.courseware_id){
        this.$tips.error({text:"请选择会议讲题"})
        return false;
      }
      return true;
    },
    goToPreview(){
      this.$tools.setStorage("coursewareItem",{
        data:this.data,
        meetingTime:this.meetingTime
      },sessionStorage)
      this.$router.push({
        path:"/coursePreview",
        query:{
          id:this.data.courseware_id
        }
      })
    },
    doCommit(){
      if(this.isDisabled() || this.loading || !this.verifyData()) return;
      this.loading = true;
      let key = this.data.id ? "patch" : "post";
      this.$axios[key](this.$urls.admin.meet,this.data).then(res=>{
        let text = `${this.$route.query.meetId ? "修改" : "申请" }会议成功`
        this.$tips.success({text})
        this.$router.push({
          path:"doctorMeets",
          query:{id:this.$route.query.id}
        })
      }).catch(err=>{
        setTimeout(()=>{ this.loading = false },1000)
      })
    }
  }
}
</script>
<style>
.meetingInfo_container{
  padding:4vw;
}
.meetingInfo_row{
  display: flex;
  align-items: center;
  margin-top:2vw;
}

.meetingInfo_row .global_text_input{
  border:none;
}
.meetingInfo_value {
  border-bottom:1px solid #e6e6e6 !important;
  padding:0 1vw;
  flex:1 0 0;
  position: relative;
}
.meetingInfo_value .el-input__icon{
  display: none;
}
.meetingInfo_value .el-input__inner{
  padding-left:1vw;
}
.meetingInfo_key{
  white-space: nowrap;
  /* margin-right:4vw;
  width: 13vw;
  word-break: break-all; */
}
.doctor_resumeInfo_key{
  margin-right:4vw;
  width: 13vw;
  word-break: break-all;
}
.meetingInfo_header img{
  height:auto;;
  width:4vw;
  margin-right:2vw;
}
.meetingInfo_body:nth-child(2){
  margin-top:8vw;
}
.meetingInfo_button_group{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:8vw;
}
.meetingInfo_button{
    width:74.66vw;
    height:9.4vw;
}
.meetingInfo_button_info{
    height:100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.meetingInfo_button_info img{
    height: 5.1vw;
    width:auto;
    margin-right:1vw;
}
.meetingInfo_course_select_container{
  height: 80%;
  width:80%;
  overflow: hidden;
}
.meetingInfo_course_select_container_center{
  width:80%;
}
.meetingInfo_course_select_title{
  width:100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
}
.meetingInfo_course_select{
  background:rgba(224,224,224,0.9);
  width:100%;
  padding:2vw 4vw;
  border-radius: 2vw;
}
.meetingInfo_course_select_ul{
  display: flex;
  align-items: center;
  border-bottom:1px solid #000;
  padding:2vw;
}
.meetingInfo_course_select_ul:last-child{
  border:none;
}
.meetingInfo_course_select_ul .el-radio{
  margin-right:0;
}
.meetingInfo_course_select_ul .el-radio__label{
  padding:0;
  width:0;
}
.meetingInfo_course_select_radio_label{
  word-break: break-all;
  margin-left:2vw;
}

.meetingInfo_courseware{
  color:#c0c4cc;
  padding:1vw 1vw;
  flex:1 0 0;
  margin-right:2vw;
  word-break: break-all;
}
.meetingInfo_courseware_select{
  color:#000;
}
.meetingInfo_value_courseware_block{
  display: flex;
  align-items: center;
}
.resume_text_input{
  min-height: 8.8vw;
  word-break: break-all;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}
.meetingInfo_value_doctorInfo_profile{
  width: 200px;
  height: 200px;
}
.meetingInfo_value_doctorInfo_profile img{
  width: 100%;
  height: 100%;
}
.doctor_resumeInfo_tips{
  margin-top: 20px;
  color: red;
  width: 100%;
  font-size: 3.5vw;
  display: flex;
  align-items: top;
  justify-content: flex-start;
}
</style>
